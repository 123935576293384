import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Container, Card, Button, Col, OverlayTrigger, Popover } from 'react-bootstrap';
import { CardBody } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle, faSort, faInfoCircle, faEyeSlash, faFileAlt, faEye } from '@fortawesome/free-solid-svg-icons'
import "bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "./TableContainer"
import { useSelector } from "react-redux";
import { selectUser } from "../auth/authSlice";
import { LaunchSurveyData } from "../launchsurvey/launchSurveyData";
import { selectEqPortalAccess } from '../eqportalaccess/eqPortalAccessSlice';
import { FileUploads } from "./FileUploads";

export const AllocatedSurveyData = (props) => {

  let surveys = props.surveys
  const eqportalaccess = useSelector(selectEqPortalAccess);
  const user = useSelector(selectUser);
  let allSurveys = []

  function byKeyLaunch(arr, key) {
    for (var i = 0, L = arr.length; i < L; i++) {
      if (arr[i].SK === key) {
        return arr[i].PIN;
      }
    }
  }

  function byKeySubmissionStatus(arr, key) {
    for (var i = 0, L = arr.length; i < L; i++) {
      if (arr[i].SK === key) {
        if (arr[i].PIN === "NONEQ") {
          return '-'
        }
        if (arr[i].eQSubmissionStatus) {
          return "Submitted"
        }
        else return "Not Submitted"
      }
    }
  }

  function byKeyStartDate(arr, key) {
    const keySK = key.SK
    for (var i = 0, L = arr.length; i < L; i++) {
      if (arr[i].SK === keySK) {
        if (arr[i].PINStartDate) {
          return arr[i].PINStartDate
        }
        else {
          return key.survey_start_date
        }
      }
    }
  }

  function byKeyEndDate(arr, key) {
    const keySK = key.SK
    for (var i = 0, L = arr.length; i < L; i++) {
      if (arr[i].SK === keySK) {
        if (arr[i].PINEndDate) {
          return arr[i].PINEndDate
        }
        else {
          return key.survey_end_date
        }
      }
    }
  }

  function dateFormat(longDate) {
    if (longDate && longDate !== "" && longDate !== " ") {
      const unixStamp = Date.parse(longDate);
      const newDate = new Date(unixStamp)
      //const readableDate = newDate
      const dateString = newDate.getDate().toString() +
        "/" + (newDate.getMonth() + 1).toString() +
        "/" + newDate.getFullYear().toString()

      return dateString;
    }
    else return null;
  }

  const renderRowSubComponent = row => {

    const uploads = surveys.filter(obj => {
      return (obj.SK === row.original.launch) && obj.fileUploads && obj.fileUploads.length > 0
    })

    return (
      uploads.length === 0 ? (
        <div style={{ margin: "0 auto", textAlign: "center" }}> No file upload data available </div>
      ) : (
        <Card>
          <CardBody>
            <FileUploads uploads={uploads[0].fileUploads} />
          </CardBody>
        </Card>
      )
    )
  }

  if (surveys && surveys.length > 0) {
    surveys.map((surveyRow, key) => {

      return allSurveys.push({
        id: surveyRow.author_title,
        start_date: dateFormat(byKeyStartDate(surveys, surveyRow)),
        end_date: dateFormat(byKeyEndDate(surveys, surveyRow)),
        submission_status: byKeySubmissionStatus(surveys, surveyRow.SK),
        launch: surveyRow.SK,
        download: surveyRow.survey_id,
        upload: surveyRow.survey_id
      })
    })
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Survey Title',
        accessor: 'id',
        disableFilters: true,
        disableSortBy: true
      }, {
        disableFilters: true,
        disableSortBy: false,
        Header: () => (
          <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={(
              <Popover id="popover-basic">
                <Popover.Content>
                  Sort surveys by start date
                </Popover.Content>
              </Popover>
            )} >
            <div>
              Start Date <FontAwesomeIcon icon={faSort} />
            </div>
          </OverlayTrigger>
        ),
        sortable: true,
        resizable: false,
        accessor: 'start_date',
        Cell: props => {
          return (
            <div style={{ textAlign: "left" }} >
              {props.value}
            </div>
          )
        }
      }, {
        disableFilters: true,
        disableSortBy: false,
        Header: () => (
          <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={(
              <Popover id="popover-basic">
                <Popover.Content>
                  Sort surveys by end date
                </Popover.Content>
              </Popover>
            )} >
            <div>
              End Date <FontAwesomeIcon icon={faSort} />
            </div>
          </OverlayTrigger>),
        sortable: true,
        resizable: false,
        accessor: 'end_date',
        Cell: props => {
          return (
            <div style={{ textAlign: "left" }} >
              {props.value}
            </div>
          )
        }
      }, {
        disableFilters: true,
        disableSortBy: false,
        Header: () => (
          <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={(
              <Popover id="popover-basic">
                <Popover.Content>
                  Sort surveys by submission status
                </Popover.Content>
              </Popover>
            )} >
            <div>
              Submission Status <FontAwesomeIcon icon={faSort} />
            </div>
          </OverlayTrigger>),
        sortable: true,
        resizable: false,
        accessor: 'submission_status',
        Cell: props => {
          return (
            <div style={{ textAlign: "left" }} >
              {props.value}
            </div>
          )
        }
      }, {
        disableFilters: true,
        disableSortBy: true,
        Header: () => (
          <div>
            Launch
          </div>
        ),
        sortable: false,
        resizable: false,
        accessor: 'launch',
        Cell: props => {
          return (
            <div style={{ textAlign: "center" }} >
              {/* <Button 
                         disabled={byKeyLaunch(surveys, props.value)==='NONEQ'}
                         variant="warning" 
                         onClick={() => (LaunchSurveyData([user.cso_identifier, user.username, pin]))}
                         >Launch
                     </Button> */}
              <Button disabled={byKeyLaunch(surveys, props.value) === 'NONEQ'} variant="warning" name="action_launch" onClick={() => (LaunchSurveyData([user.cso_identifier, user.username, byKeyLaunch(surveys, props.value)]))}>Launch</Button>
            </div>
          )
        }
      },
      {
        disableFilters: true,
        disableSortBy: true,
        Header: () => (
          <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={(
              <Popover id="popover-basic">
                <Popover.Content>
                  Use this button to download templates required to fill out the survey.
                </Popover.Content>
              </Popover>
            )} >
            <div>
              Download Template <FontAwesomeIcon icon={faQuestionCircle} />
            </div>
          </OverlayTrigger>
        ),
        sortable: false,
        resizable: false,
        accessor: 'download',
        Cell: props => {
          return (
            <div style={{ textAlign: "center" }} >
              <Link to={`/home/download/${props.value}`} >
                <Button variant="warning">Download Template</Button>
              </Link>
            </div>
          )
        }
      }, {
        disableFilters: true,
        disableSortBy: true,
        Header: () => (
          <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={(
              <Popover id="popover-basic">
                <Popover.Content>
                  Use this button to upload files/receipts to accompany the survey.
                </Popover.Content>
              </Popover>
            )} >
            <div>
              Upload Files <FontAwesomeIcon icon={faQuestionCircle} />
            </div>
          </OverlayTrigger>),
        sortable: false,
        resizable: false,
        accessor: 'upload',
        Cell: props => {
          return (
            <div style={{ textAlign: "center" }} >
              <Link to={`/home/upload/${props.value}`} >
                <Button variant="secondary">Upload File</Button>
              </Link>
            </div>
          )
        }
      }, {
        Header: () => (
          <OverlayTrigger
            placement="top"
            delay={{ show: 150, hide: 300 }}
            overlay={(
              <Popover id="popover-basic">
                <Popover.Content>
                  View details of previously uploaded files.
                </Popover.Content>
              </Popover>
            )} >
            <div style={{ textAlign: "center" }}>
              <FontAwesomeIcon icon={faFileAlt} />
            </div>
          </OverlayTrigger>),
        id: 'expander', // 'id' is required
        Cell: ({ row }) => (
          <div style={{ textAlign: "center" }} >
            <span {...row.getToggleRowExpandedProps()}>
              {row.isExpanded ? <FontAwesomeIcon icon={faEyeSlash} title="Toggle Row Expanded" /> : <FontAwesomeIcon icon={faEye} title="Toggle Row Hidden" />}
            </span>
          </div>
        )
      }
    ],
    [surveys, user.cso_identifier, user.username]
  )

  return (
    <Col style={{ paddingLeft: 50, paddingRight: 50 }}>
      <Container fluid className='pt-5'>
        <Card>
          <Card.Header style={{ backgroundColor: "#FBAA34", color: "white" }}>
            <h3 style={{ textAlign: 'left', float: 'left' }}>Available Surveys &nbsp;</h3>
            <OverlayTrigger
              placement="top"
              delay={{ show: 150, hide: 300 }}
              overlay={(
                <Popover id="popover-basic">
                  <Popover.Content>
                    {eqportalaccess && "Once a survey is submitted it may take a few minutes for the tables to update."}
                    {!eqportalaccess && "Once a file has been uploaded it may take a few minutes for the tables to update."}
                  </Popover.Content>
                </Popover>
              )} >
              <h5 style={{ textAlign: 'right', float: 'right' }}><FontAwesomeIcon icon={faInfoCircle} /></h5>
            </OverlayTrigger>
          </Card.Header>
          <Card.Body>
            <TableContainer columns={columns} data={allSurveys} renderRowSubComponent={renderRowSubComponent} />
          </Card.Body>
        </Card>
      </Container>
    </Col>
  );
};

export default AllocatedSurveyData;
