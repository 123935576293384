import React, { useState }  from 'react';
import { Button, Form } from 'react-bootstrap';
import { showError, showInfo } from '../messages/messagesSlice';
import { showSuccess} from '../messages/messagesSlice';
import { showWarning} from '../messages/messagesSlice';
import { selectUser } from '../auth/authSlice';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import API from '../../services/api';
import {SURVEYS_API_URL} from '../../config/service';

export const UploaderCSO = (props) => {

  const [fileState, setFileState] = useState({});
  const [messageState, setMessageState] = useState('');
  // const [commentState, setCommentState] = useState('');
  const [fileSelected, setFileSelected] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);


  const fileTypes = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                      'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/xml', 'text/xml', 'text/csv',
                      'application/pdf', 'application/zip']


  const getFile = (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      const type = file.type;

      if(file.size === 0)
      {
        setMessageState( "File: " + file.name + " is an empty file and cannot be uploaded." )
      }
      else if (file.size > 10000000)
      {
        setMessageState("File: " + file.name + " is too big to be uploaded, file must be less than 10MB." )
      }
      else if (fileTypes.includes(type))
      {
        setFileState({ file });
        setFileSelected(true)
      }
      else 
      {
        setMessageState('File: ' + file.name + ' is an invalid file type. Accepted file types are: .doc, .docx, .xls, .xlsx, .xml, .csv, .pdf, .zip');
      }
    }
  };



  const uploadFile = (e) => {
    e.preventDefault();
    const { file } = fileState;
    setMessageState('Uploading...')
    const folder = 'quarantine/' + props.survey + '/ORG#' + props.org + '/'
    const fileNameLessSpaces = file.name.replace(/ /g, "_");
    const keyString = folder + fileNameLessSpaces
    const contentType = file.type

      const urlOptions = {
        params: {
          Key: keyString,
          ContentType: contentType,
          Filename: file.name,
        },
        headers: {
          'Content-Type': contentType
        }
      };

      API.get(`${SURVEYS_API_URL}/v1/surveys/s3/put`, urlOptions).then(res => {
          
          return res.data})
          .then(function(putURL) {

              fetch(putURL, {
                  method: 'PUT',
                  mode: 'cors',
                  body: file
                  }).then(function(res){

                      if(res.status === 200)
                      {
                        dispatch(showInfo({ "message": file.name + " is being scanned for viruses and malware..." }))
                        API.get(`${SURVEYS_API_URL}/v1/surveys/s3/scan`, urlOptions).then(function(scanRes){
                          
                          if(scanRes.data.is_infected === true)
                          {
                            const dbOptions = {
                              params: {
                                cso_id: 'ORG#' + props.org,
                                survey_id: props.survey,
                                username: user.username,
                                file_name: file.name,
                                file_status: "infected/deleted"
                              }
                            };

                            API.get(`${SURVEYS_API_URL}/v1/surveys/s3/remove`, urlOptions)
                            dispatch(showWarning({ "message": file.name + " is infected and cannot be uploaded." }))
                            
                            
                            API.get(`${SURVEYS_API_URL}/v1/surveys/s3/record`, dbOptions)
                          }
                          else if(scanRes.data.is_infected === false)
                          {


                            const dbOptions = {
                              params: {
                                cso_id: 'ORG#' + props.org,
                                survey_id: props.survey,
                                username: user.username,
                                
                                file_name: file.name,
                                file_status: "clean/verified"
                              }
                            };

                            API.get(`${SURVEYS_API_URL}/v1/surveys/s3/copy`, urlOptions)
                            dispatch(showSuccess({ "message": file.name + " has been uploaded successfully." }))
                            const fileref = document.getElementById('upload-file');
                            fileref.value = '';
                            setMessageState('');
                            API.get(`${SURVEYS_API_URL}/v1/surveys/s3/record`, dbOptions);
                            // if(commentBool)
                            // {
                            //   API.get(`${SURVEYS_API_URL}/v1/surveys/s3/comment`, urlOptions).then(function(commentRes)
                            //   {
                            //     if(commentRes.status === 201)
                            //     {
                            //       dispatch(showSuccess({ "message": "Your comment has been attached successfully." }))
                            //     }
                            //     else
                            //     {
                            //       dispatch(showWarning({ "message": "Something went wrong, comment failed to attach. " }))
                            //     }
                            //   })
                            // }
                          }
                          else 
                          {
                            API.get(`${SURVEYS_API_URL}/v1/surveys/s3/remove`, urlOptions)
                            dispatch(showError({ "message": "Process timed out, please try again."}))
                            
                          }
                        })
                      }
                    })
                  .catch((error) => {
                    dispatch(showError({ "message": error.message }));
                  });

                  setFileState({});
                  setFileSelected(false);
                  
            })
           
       };

    return (
      <Form.Group>
        <input
          id='upload-file'
          type='file'
          accept=".xls,.xlsx,.xml,.doc,.docx,.pdf,.csv,.zip"
          onChange={getFile}
        />
        <p>{messageState}</p>
        
        <div style={{float: "right"}} className="pt-2 pr-2">
          <Link to = {`/home`}>
            <Button
              >Back
            </Button>
          </Link>
        </div>
        <div style={{float: "right"}} className="pt-2 pr-2">
    <Button variant='info' disabled={!fileSelected} onClick={uploadFile}>Upload</Button>
        </div>
      </Form.Group>
      
    );
}